import { defineStore } from "pinia";
import { getDateForInterval } from "@/helpers/filter";
import { subDays, format, differenceInDays, startOfDay, endOfDay } from "date-fns";
import { removeQueryParams, setQueryParams } from "@/services/queryParams";
import { AdvancedFilterQuery, StorageKeys, TranscriptionQueryTypes, TranscriptionSearchOptions, defaultAdvancedFilter, defaultTimeInterval, defaultTrancriptSearch } from "@/constants/advanced-search";
import { AdvancedFilters, Direction, FilterState, SelectedAdvancedFilters } from "@/types/advancedSearch";
import { ITranscriptionQuery } from "@/models/shared.model";

export const useFilterStore = defineStore("filter", {
  state: () => ({
    currentAdvancedFiltersOption: "",
    intervals: { ...defaultTimeInterval },
    isLoading: true,
    isError: false,
    sentimentByTopicSorting: {
      direction: "desc"
    },
    appliedAdvancedFilters: { ...defaultAdvancedFilter },
    draftAdvancedFilters: { ...defaultAdvancedFilter },
    isAdvancedFilterOpen: false,
    isManuallyUpdated: false,
    newCustomPeriodIntervals: [],
    currentAdvancedFiltersState: null
  }) as unknown as FilterState,
  getters: {
    getFormatStringDateIntervals(state) {
      if (state.intervals.isCustomPeriod && Array.isArray(state.intervals.timePeriod)) {
        let differenceDays = differenceInDays(
          state.intervals.timePeriod[1] as unknown as Date,
          state.intervals.timePeriod[0] as unknown as Date
        ) + 1;
        return {
          CurrentDate: {
            StartDate: format(state.intervals.timePeriod[0] as unknown as Date, "yyyy-MM-dd"),
            EndDate: format(state.intervals.timePeriod[1] as unknown as Date, "yyyy-MM-dd"),
          },
          PreviousDate: state.intervals.comparisonPeriod ? {
            PreviousStartDate: format(
              subDays(state.intervals.timePeriod[0] as unknown as Date, differenceDays),
              "yyyy-MM-dd"
            ),
            PreviousEndDate: format(
              subDays(state.intervals.timePeriod[1] as unknown as Date, differenceDays),
              "yyyy-MM-dd"
            )
          } : getDateForInterval(state.intervals?.timePeriod, state.intervals?.comparisonPeriod, "yyyy-MM-dd")
        };
      }
      else {
        return {
          CurrentDate: getDateForInterval(state.intervals?.timePeriod, false, "yyyy-MM-dd"),
          PreviousDate: getDateForInterval(state.intervals?.timePeriod, state.intervals?.comparisonPeriod, "yyyy-MM-dd")
        };
      }
    },
    getAppliedAdvancedFilters(state): SelectedAdvancedFilters {
      return {
        campaignId: getSelectedId(state.appliedAdvancedFilters.campaigns),
        teamId: getSelectedId(state.appliedAdvancedFilters.teams),
        userId: getSelectedId(state.appliedAdvancedFilters.users),
        queueId: getSelectedId(state.appliedAdvancedFilters.queues),
        resultCode: getSelectedId(state.appliedAdvancedFilters.resultCodes),
        duration: getSelectedId(state.appliedAdvancedFilters.durations),
        callDirections: getSelectedId(state.appliedAdvancedFilters.callDirections),
        excludeShortCalls: state.appliedAdvancedFilters.excludeShortCalls as boolean || false,
        onlyShortCalls: state.appliedAdvancedFilters.onlyShortCalls as boolean || false,
        transcriptSearch: state.appliedAdvancedFilters.transcriptSearch || { ...defaultTrancriptSearch }
      };
    },
    getIntervals(state) {
      if (state.intervals.isCustomPeriod && Array.isArray(state.intervals.timePeriod)) {
        const differenceDays = differenceInDays(
          state.intervals.timePeriod[1] as unknown as Date, state.intervals.timePeriod[0] as unknown as Date
        ) + 1;
        return {
          CurrentDate: {
            StartDate: state.intervals.timePeriod[0],
            EndDate: state.intervals.timePeriod[1],
          },
          PreviousDate: state.intervals.comparisonPeriod ? {
            PreviousStartDate: subDays(state.intervals.timePeriod[0] as unknown as Date, differenceDays),
            PreviousEndDate: subDays(state.intervals.timePeriod[1] as unknown as Date, differenceDays)
          } : getDateForInterval(state.intervals?.timePeriod, state.intervals?.comparisonPeriod)
        };
      }
      else {
        return {
          CurrentDate: getDateForInterval(state.intervals?.timePeriod, false),
          PreviousDate: getDateForInterval(state.intervals?.timePeriod, state.intervals?.comparisonPeriod)
        };
      }
    },
    getIsAdvancedFilterChanged(state): boolean {
      const appiledAdvancedFiltersString = JSON.stringify(state.appliedAdvancedFilters);
      const draftAdvancedFiltersString = JSON.stringify(state.draftAdvancedFilters);
      return appiledAdvancedFiltersString !== draftAdvancedFiltersString;
    },
    getPreviousPeriod(state) {
      if (Array.isArray(state.intervals.timePeriod)) {
        let difference = differenceInDays(
          state.intervals.timePeriod[1],
          state.intervals.timePeriod[0]
        ) + 1;
        return [
          startOfDay(subDays(state.intervals.timePeriod[0], difference)),
          endOfDay(subDays(state.intervals.timePeriod[1], difference)),
        ];
      }
    },
    getAnalyticsOptionsToUpdateData(state) {
      const previousDate = state.getFormatStringDateIntervals.PreviousDate;
      const currentDate = state.getFormatStringDateIntervals.CurrentDate;

      // Filter out undefined properties
      const filteredCurrentDate = Object.fromEntries(Object.entries(currentDate).filter(([_, v]) => !!v));
      const filteredPreviousDate = Object.fromEntries(Object.entries(previousDate).filter(([_, v]) => !!v));

      const advancedFiltersItems = {};

      // When you will have an array (multiselect)
      const queryParams: string[] = [];
      Object.keys(state.appliedAdvancedFilters).forEach(key => {
        if(key === "excludeShortCalls" || key === "onlyShortCalls") {
          advancedFiltersItems[AdvancedFilterQuery[key]] = state.appliedAdvancedFilters[key];
          return;
        }
        if (state.appliedAdvancedFilters[key].selected === "all" || !state.appliedAdvancedFilters[key].selected?.length) return;


        const advancedFilterQueryKey = AdvancedFilterQuery[key];
        state.appliedAdvancedFilters[key].selected.forEach((id) => {
          const obj = {};
          obj[advancedFilterQueryKey] = id.value;
          queryParams.push(new URLSearchParams(obj).toString());
        });
      });
      const queryParamsString = "&" + queryParams.join("&");
      const options = new URLSearchParams({
        ...advancedFiltersItems,
        ...filteredCurrentDate,
        ...filteredPreviousDate,
      }).toString().concat(queryParamsString);
      state.intervals.selectedDates = {
        currentFirstDate: startOfDay(new Date(filteredCurrentDate.StartDate as string)),
        currentLastDate: startOfDay(new Date(filteredCurrentDate.EndDate as string)),
        previousFirstDate: startOfDay(new Date(filteredPreviousDate.PreviousStartDate as string)),
        previousLastDate: startOfDay(new Date(filteredPreviousDate.PreviousEndDate as string))
      };

      return {
        options:  "&" + options,
        timePeriod: state.intervals.timePeriod,
        comparisonPeriod: state.intervals.comparisonPeriod
      };
    },
    getTranscriptionSearchQueries(state): ITranscriptionQuery[] | undefined {
      let queries = [];
      if (state.appliedAdvancedFilters.transcriptSearch && state.appliedAdvancedFilters.transcriptSearch.text) {
        queries.push({
          text: state.appliedAdvancedFilters.transcriptSearch.text,
          operator: state.appliedAdvancedFilters.transcriptSearch.option,
          type: TranscriptionQueryTypes.text,
        });
      }
      if (state.intervals.interactionsSearch && state.intervals.interactionsSearch.text) {
        queries.push({
          text: state.intervals.interactionsSearch.text,
          operator: state.intervals.interactionsSearch.option,
          type: TranscriptionQueryTypes.metadata,
        });
      }

      return queries.length ? queries : undefined;
    },
  },
  actions: {
    resetAllNonAdvancedFiltersQueryParams(){
      this.updateSelectedTopic("");
      this.updateSentiment("");
      this.updateObjection("");
      this.updateObjectionCategory("");
      this.updateIsSuccessInteraction();
      this.updateInteractionsSearch("");
    },
    updateSpeechAnalyticsFilters(successIntelligenceFilters, timePeriod: Date[]) {
      Object.keys(successIntelligenceFilters).forEach((key) => {
        if (!successIntelligenceFilters[key].selected) return;
        if (typeof successIntelligenceFilters[key].selected !== "string") {
          this.updateAppliedAdvancedFilters(key, [...successIntelligenceFilters[key].selected], false);
        }
      });
      const [timePeriodStartDate, timePeriodEndDate] = timePeriod;
      this.updateIntervals("timePeriod", [timePeriodStartDate, timePeriodEndDate]);
    },
    updateSentiment(sentiment: string) {
      this.intervals.sentiment = sentiment;
      setQueryParams({ sentiment: sentiment });
    },
    updateObjection(objection: string) {
      this.intervals.objection = objection;
      setQueryParams({ objection: objection });
    },
    updateObjectionCategory(objectionCategory: string) {
      this.intervals.objectionCategory = objectionCategory;
      setQueryParams({ objectionCategory: objectionCategory });
    },
    updateIsSuccessInteraction(isSuccess: boolean | undefined = undefined) {
      this.intervals.isSuccess = isSuccess;

      if (isSuccess !== undefined) {
        setQueryParams({ isSuccess: isSuccess })
      }
      else {
        removeQueryParams(["isSuccess"]);
      }
    },
    updateInteractionsSearch(value: string) {
      if (value) {
        this.intervals.interactionsSearch = {
          option: TranscriptionSearchOptions.contains,
          text: value
        };
        setQueryParams({ interactionsSearch: this.intervals.interactionsSearch });
      }
      else {
        this.intervals.interactionsSearch = undefined;
        removeQueryParams(["interactionsSearch"]);
      }
    },
    updateSelectedTopic(topic: string) {
      this.intervals.selectedTopic = topic;
      setQueryParams({ selectedTopic: topic });
    },
    saveCurrentFilterState() {
      this.currentAdvancedFiltersState = {
        search: localStorage.getItem(StorageKeys.search),
        appliedAdvancedFilters: localStorage.getItem(StorageKeys.appliedAdvancedFilters),
        draftAdvancedFilters: localStorage.getItem(StorageKeys.draftAdvancedFilters)
      };
    },
    clearInitialFilterState() {
      this.currentAdvancedFiltersState = null;
    },
    setIsAdvancedFilterOpen(val: boolean) {
      this.isAdvancedFilterOpen = val;
    },
    // isSetupManually it's a property that show us the change was made within the code or through the UI;
    // false - within the code, true - by the UI interaction(mouse clicks, etc.)
    updateAppliedAdvancedFilters(type: string, selectedList,  isSetupManually?: boolean) {
      this.appliedAdvancedFilters[type] = {
        selected: selectedList,
      };
      this.setAppliedAdvancedFiltersToLocalStorage();
      this.isManuallyUpdated = !!isSetupManually;
    },
    setAppliedAdvancedFilters(filters: Partial<AdvancedFilters>) {
      this.appliedAdvancedFilters = filters;
    },
    resetAppliedAdvancedFilters(isSetupManually?: boolean) {
      this.isManuallyUpdated = isSetupManually;
      this.appliedAdvancedFilters = structuredClone(defaultAdvancedFilter);
      this.setAppliedAdvancedFiltersToLocalStorage();
      this.resetDraftAdvancedFilters();
    },
    updateDraftAdvancedFilters(type: string, selectedList: any) {
      this.currentAdvancedFiltersOption = type;
      this.draftAdvancedFilters[type] = {
        selected: selectedList.length > 0 ? selectedList : "all",
      };
      this.setDraftAdvancedFiltersToLocalStorage();
    },
    setDraftAdvancedFilters(filter: Partial<AdvancedFilters>) {
      this.draftAdvancedFilters = { ...filter };
    },
    applyDraftAdvancedFilters() {
      Object.keys(this.draftAdvancedFilters).forEach((key: string) => {
        if (key === "excludeShortCalls" || key === "onlyShortCalls") {
          this.appliedAdvancedFilters[key] = this.draftAdvancedFilters[key];
        }
        else if (key === "transcriptSearch") {
          this.appliedAdvancedFilters[key] = {
            option: this.draftAdvancedFilters[key]?.option,
            text: this.draftAdvancedFilters[key]?.text
          };
        }
        else {
          this.appliedAdvancedFilters[key] = { selected: this.draftAdvancedFilters[key]?.selected };
        }
      });

      this.isManuallyUpdated = true;
      this.setAppliedAdvancedFiltersToLocalStorage();
    },
    resetDraftAdvancedFilters() {
      this.draftAdvancedFilters = structuredClone(defaultAdvancedFilter);
      this.setDraftAdvancedFiltersToLocalStorage();
    },
    setIsCustomPeriod(value: boolean) {
      this.intervals.isCustomPeriod = value;
      const queryParams = localStorage.getItem(StorageKeys.search);
      if (value) {
        const updatedParams = {
          ...JSON.parse(queryParams as string),
          isCustomPeriod: value,
          from: this.intervals.timePeriod[0],
          to: this.intervals.timePeriod[1]
        };
        this.setSearchCriteriaToLocalStorage(updatedParams);
      }
      if (!value && queryParams) {
        const updateQueryParams = {
          ...JSON.parse(queryParams),
          isCustomPeriod: value,
          from: null,
          to: null
        };
        this.setSearchCriteriaToLocalStorage(updateQueryParams);
      }
    },
    resetIntervals() {
      this.intervals = { ...defaultTimeInterval };
      const previousDate = this.getFormatStringDateIntervals.PreviousDate;
      const currentDate = this.getFormatStringDateIntervals.CurrentDate;

      // Filter out undefined properties
      const filteredCurrentDate = Object.fromEntries(Object.entries(currentDate).filter(([_, v]) => !!v));
      const filteredPreviousDate = Object.fromEntries(Object.entries(previousDate).filter(([_, v]) => !!v));

      this.intervals.selectedDates = {
        currentFirstDate: startOfDay(new Date(filteredCurrentDate.StartDate as string)),
        currentLastDate: startOfDay(new Date(filteredCurrentDate.EndDate as string)),
        previousFirstDate: startOfDay(new Date(filteredPreviousDate.PreviousStartDate as string)),
        previousLastDate: startOfDay(new Date(filteredPreviousDate.PreviousEndDate as string))
      };
    },
    updateIntervals(type: string, value: string | Date[], isSetupManually?: boolean) {
      let newVal = value;
      this.isManuallyUpdated = !!isSetupManually;
      const queryParams = {};
      if (type === "comparisonPeriod" || type === "isCustomPeriod") {
        newVal = JSON.parse(value);
      }
      this.intervals[type] = newVal;
      queryParams[type] = value;
      if (Array.isArray(value)) {
        queryParams.isCustomPeriod = true;
        queryParams[type] = "customPeriod";
        let sortedDates = value.sort((a, b) => a - b);
        queryParams.from = sortedDates[0];
        queryParams.to = sortedDates[1];
      }
      if (type === "timePeriod" && typeof value === "string") {
        removeQueryParams(["to", "from"]);
      }
      setQueryParams(queryParams);
    },
    updateExcludeShortCalls(value: boolean) {
      this.draftAdvancedFilters.excludeShortCalls = value;
      this.setDraftAdvancedFiltersToLocalStorage();
    },
    updateOnlyShortCalls(value: boolean) {
      this.draftAdvancedFilters.onlyShortCalls = value;
      this.setDraftAdvancedFiltersToLocalStorage();
    },
    updateTranscriptionSearch(paramName: string, value: string) {
      if (!this.draftAdvancedFilters.transcriptSearch) {
        return;
      }
      switch(paramName) {
        case "option": {
          this.draftAdvancedFilters.transcriptSearch.option = value;
           break;
        }
        case "text": {
          this.draftAdvancedFilters.transcriptSearch.text = value;
           break;
        }
     }

      this.setDraftAdvancedFiltersToLocalStorage();
    },
    resetTranscriptSearch() {
      this.appliedAdvancedFilters.transcriptSearch = { ...defaultTrancriptSearch };
      this.draftAdvancedFilters.transcriptSearch = { ...defaultTrancriptSearch };
      this.setAppliedAdvancedFiltersToLocalStorage();
      this.setDraftAdvancedFiltersToLocalStorage();
    },
    setSentimentByTopicSort(sort: Direction) {
      this.sentimentByTopicSorting.direction = sort;
    },
    setAppliedAdvancedFiltersToLocalStorage() {
      localStorage.setItem(StorageKeys.appliedAdvancedFilters, JSON.stringify(this.appliedAdvancedFilters));
    },
    setDraftAdvancedFiltersToLocalStorage() {
      localStorage.setItem(StorageKeys.draftAdvancedFilters, JSON.stringify(this.draftAdvancedFilters));
    },
    setSearchCriteriaToLocalStorage(searchCriteria: any) {
      localStorage.setItem(StorageKeys.search, JSON.stringify(searchCriteria));
    }
  }
});

const getSelectedId = (item) => {
  return item?.selected === "all" ? null : item?.selected.map((item) => item?.value);
};