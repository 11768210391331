import { getDaysInQuarter } from "@/helpers/chart";
import { TranscriptSearchFilterItem, Intervals, IAdvancedSuccessFilter } from "@/types/advancedSearch";
import { differenceInCalendarDays, getDaysInMonth, getDaysInYear } from "date-fns";

const SEARCH_OPTIONS = [{
  title: "Time Period",
  id: "timePeriod",
  options: [
    { name: "Yesterday", id: "yesterday" },
    { name: "7 Days", id: "sevenDays" },
    { name: "2 Weeks", id: "twoWeeks" },
    { name: "Month", id: "month" },
    { name: "Quarter", id: "quarter" },
    { name: "Year", id: "year" },
    { name: "Custom Period", id: "customPeriod" },
  ],
  selected: "sevenDays",
}, {
  title: "Date Granularity",
  id: "dateGranularity",
  options: [
    { name: "Day", id: "day" },
    { name: "Week", id: "week" },
    { name: "Month", id: "month" },
    { name: "Quarter", id: "quarter" },
  ],
  selected: "day",
}];

enum AdvancedFilterQuery {
  campaigns = "CampaignIds",
  teams = "TeamIds",
  queues = "QueueIds",
  users = "UserIds",
  resultCodes = "ResultCodes",
  durations = "Durations",
  callDirections = "CallDirections",
  onlyShortCalls = "OnlyShortCalls",
  excludeShortCalls = "ExcludeShortCalls"
};

enum FILTER_OPTIONS {
  campaigns = "Campaign",
  teams = "Team",
  queues = "Queue",
  users = "User",
  callDirections = "Interaction Type",
  durations = "Call Duration",
  resultCodes = "Result Code",
};


enum Granularity {
  day = "Day",
  week = "Week",
  month = "Month",
  quarter = "Quarter",
};

enum PreviousTimePeriod {
  yesterday = "Day Before",
  sevenDays = "Previous 7 Days",
  twoWeeks = "Previous 2 Weeks",
  month = "Previous Month",
  quarter = "Previous Quarter",
  year = "Previous Year",
  customPeriod = "Custom Period",
};

enum StorageKeys {
  appliedAdvancedFilters = "appliedAdvancedFilters",
  draftAdvancedFilters = "draftAdvancedFilters",
  search = "search"
};

enum SUCCESS_FILTER_OPTIONS {
  campaigns = "Campaign",
  teams = "Team",
  queues = "Queue",
  users = "User",
  callDirections = "Interaction Type",
  durations = "Call Duration",
};

enum TimePeriod {
  yesterday = "Yesterday",
  sevenDays = "7 Days",
  twoWeeks = "2 Weeks",
  month = "Month",
  quarter = "Quarter",
  year = "Year",
  customPeriod = "Custom Period",
};

enum TimePeriodToInterval {
  yesterday = 1,
  sevenDays = 7,
  twoWeeks = 14,
  month = 1,
  quarter = 1,
  year = 1,
  customPeriod = "Custom Period",
};

enum TimePeriodToIntervalDays {
  yesterday = 1,
  day = 1,
  sevenDays = 7,
  twoWeeks = 14,
  month = 30,
  quarter = 90,
  year = 365,
  week = 7,
  customPeriod = "Custom Period",
};

enum TranscriptionQueryTypes {
  text = 'Text',
  metadata = 'Metadata',
};

enum TranscriptionSearchOptions {
  contains = 'Contains',
  notContains = 'NotContains',
};

enum TrendingTimePeriod {
  yesterday = "Yesterday",
  sevenDays = "Week",
  twoWeeks = "2 Weeks",
  month = "Month",
  quarter = "Quarter",
  year = "Year",
  customPeriod = "Custom Period",
};

const defaultTimeInterval: Intervals = {
  timePeriod: "sevenDays",
  comparisonPeriod: true,
  dateGranularity: "day",
  isCustomPeriod: false,
  selectedTopic: "",
  sentiment: ""
};

const defaultTrancriptSearch: TranscriptSearchFilterItem = {
  option: TranscriptionSearchOptions.contains,
  text: ""
};

const TimePeriodToIntervalDaysMappingFunctions = {
  yesterday: () => 1,
  day: () => 1,
  sevenDays: () => 7,
  twoWeeks: () => 14,
  month: (date: Date) => getDaysInMonth(date),
  quarter: (date: Date) => getDaysInQuarter(date),
  year: (date: Date) => getDaysInYear(date),
  week: () => 7,
  customPeriod: (date: Date[]) => differenceInCalendarDays(date[1], date[0]) + 1,
};

const defaultAdvancedFilter = {
  campaigns: {
    selected: "all",
  },
  teams: {
    selected: "all",
  },
  users: {
    selected: "all",
  },
  queues: {
    selected: "all",
  },
  resultCodes: {
    selected: "all",
  },
  durations: {
    selected: "all",
  },
  callDirections: {
    selected: "all",
  },
  excludeShortCalls: false,
  onlyShortCalls: false,
  transcriptSearch: { ...defaultTrancriptSearch }
};

const defaultSuccessAdvancedFilter: IAdvancedSuccessFilter = {
  campaigns: {
    selected: "all",
  },
  teams: {
    selected: "all",
  },
  users: {
    selected: "all",
  },
  queues: {
    selected: "all",
  },
  durations: {
    selected: "all",
  },
  callDirections: {
    selected: "all",
  },
  excludeShortCalls: false,
  onlyShortCalls: false,
  transcriptSearch: { ...defaultTrancriptSearch }
};

interface GranularityPeriods {
  [key: string]: string[];
};

interface GranularityCustomPeriods {
  [key: string]: number;
};

const supportedGranularityPeriods: GranularityPeriods = {
  "quarter": ["year", "quarter"],
  "month": ["year", "quarter", "month"],
  "week": ["year", "quarter", "month", "twoWeeks", "sevenDays"],
  "day": ["year", "quarter", "month", "twoWeeks", "sevenDays", "yesterday"],
};

const supportedGranularityCustomPeriods: GranularityCustomPeriods = {
  quarter: 90,
  month: 28,
  week: 7,
  day: 1,
};

export {
  SEARCH_OPTIONS,
  FILTER_OPTIONS,
  TimePeriod,
  PreviousTimePeriod,
  TranscriptionQueryTypes,
  TranscriptionSearchOptions,
  AdvancedFilterQuery,
  TimePeriodToInterval,
  TimePeriodToIntervalDays,
  TimePeriodToIntervalDaysMappingFunctions,
  defaultTimeInterval,
  StorageKeys,
  defaultAdvancedFilter,
  defaultTrancriptSearch,
  supportedGranularityPeriods,
  supportedGranularityCustomPeriods,
  TrendingTimePeriod,
  Granularity,
  SUCCESS_FILTER_OPTIONS,
  defaultSuccessAdvancedFilter
};