import { CHART_COLORS } from "@/constants/chartColors";
import { ChartCtx } from "@/types/charts";
import { addDays, addMonths, addQuarters, addWeeks, addYears, differenceInCalendarWeeks, differenceInDays, endOfMonth, endOfQuarter, format, getDaysInMonth, getMonth, getWeek, getWeeksInMonth, isEqual, isSameMonth, parse, startOfDay, startOfMonth, startOfQuarter, startOfWeek, subDays, subQuarters} from "date-fns";
import { TimePeriodToIntervalDaysMappingFunctions } from "@/constants/advanced-search";
export const doughnutChartColor = (percent: number): string => {
  if (percent === 0) return "var(--fill-bars)";
  if (percent < 25) return "var(--very-negative)";
  if (percent < 49) return "var(--negative)";
  if (percent == 50) return "var(--neutral)";
  if (percent < 75) return "var(--positive)";
  return "var(--very-positive)";
};

export const getColor = (ctx: ChartCtx) => {
  if (!ctx.raw?.g) return;
  const colorName = ctx.raw.g;
  const color = CHART_COLORS[colorName.toUpperCase()];
  return color;
};
const minValue = 0;
const maxValue = 100;

export const randomArray = (length: number) => {
  return Array.from(
    { length: length },
    () => Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue
  );
};

export const getRgbaFromHex = (colorName, opacity: number) => {

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorName);
    const rgb = result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;

    if (rgb) {
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
    } else {
      return null;
    }
  };


  export const  formatInterval = (date: Date[]): string => {
    let formatType = "dd/MM/yyyy";
    return `<strong>${format(new Date(date[0]), formatType)} 00:00</strong> to <strong>${format(
      new Date(date[1]),
      formatType
    )} 23:59 </strong>`;
  };

  export const sortTypes = {
    asc: (a, b) => a.sum - b.sum,
    desc: (a, b) => b.sum - a.sum,
    alphanumericAsc:(a,b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: "base" }),
    alphanumericDesc:(a,b) => b.label.localeCompare(a.label, undefined, { numeric: true, sensitivity: "base" }),
  };

  export const pointBackgroundColorArray = (length, color) => {
    return Array(length)
      .fill("transparent")
      .map((_, index) => {
        if (index === 0 || index === length - 1) {
          return color;
        }
        return "transparent";
      });
  };
  export const pointBackgroundColorFromArray = (array, color) => {
    return Array(array.length)
      .fill("transparent")
      .map((_, index) => {
        let firstIndexItemNotNull = array.findIndex((i) => i !== null);
        let lastIndexNotNull = array.findLastIndex((i) => i !== null);
        if (index === lastIndexNotNull || index === firstIndexItemNotNull) {
          return color;
        }
        return "transparent";
      });
  };
  
  const AddBasedOnPeriod = {
    yesterday: addDays,
    sevenDays: addWeeks,
    twoWeeks: addWeeks,
    month: addMonths,
    quarter: addQuarters,
    year: addYears,
    day: addDays,
    week: addWeeks,
  };
  export const mapDataToDateLabels = (dateLabels: Date[], data, isPreviousData: boolean, granularity: string, period: string | Date[] ) =>  {
    let isValue = false;
    let periodsHaveSameWeekInData = 0;
    if(granularity === "week" && isPreviousData && data.length > 0) {
      const sortedData = [...dateLabels].sort((a, b) => a.getTime() - b.getTime());
      const lastPeriodFirstDate = getWeek(sortedData[0], {weekStartsOn: 1, firstWeekContainsDate: 4});
      const previousDate = getWeek(subDays(sortedData[0], 1), {weekStartsOn: 1, firstWeekContainsDate: 4});
      periodsHaveSameWeekInData = previousDate === lastPeriodFirstDate ? 1 : 0;
    }
    const previousDataDates = [];
    let mappedData = dateLabels.map((date: Date) => {
    const foundItem = data.find((i) =>  {
      const getDateOrArrayOfDates = Array.isArray(period) ? period : startOfDay(new Date(i.date));
      const periodName = Array.isArray(period) ? "customPeriod" : period;
      let differenceDays = TimePeriodToIntervalDaysMappingFunctions[periodName](getDateOrArrayOfDates);
      if(period === "month") {
        differenceDays = getDaysInMonth(new Date(i.date));
      }
      let prevPeriod = "day";
     
      if(granularity === "week"){
        date = startOfWeek(date, {weekStartsOn: 1});
        let dateToCheck = !isPreviousData ? startOfWeek(new Date(i.date), {weekStartsOn: 1}) : startOfWeek(addDays(
          startOfDay(new Date(i.date)),
          differenceDays
        ), {weekStartsOn: 1});
        if(isPreviousData && (period === "month" || isIntervalFullMonth(period)) ){
          const amountOfWeeksInMonth = getWeeksInMonth(new Date(date), {weekStartsOn: 1});
          dateToCheck = addWeeks(startOfWeek(new Date(i.date), {weekStartsOn: 1}), amountOfWeeksInMonth);
          const previousPeriodFirstDayOfWeek = startOfWeek(new Date(i.date), {weekStartsOn: 1});
          const lastPeriodFirstDayOfWeek = startOfWeek(date, { weekStartsOn: 1});

          const datesMatch = isEqual((addWeeks(previousPeriodFirstDayOfWeek, amountOfWeeksInMonth - periodsHaveSameWeekInData)), lastPeriodFirstDayOfWeek);
          if(datesMatch) {
            previousDataDates.push(i.date);
          }
          return datesMatch;
        }
        if( isEqual(
          dateToCheck,
          date
        )) {
          previousDataDates.push(i.date);
          return true;
        }       
        return false;
      }

      if(granularity === "quarter"){
        return isEqual(isPreviousData ? startOfQuarter(AddBasedOnPeriod[prevPeriod](i.date, differenceDays + 3)) : startOfQuarter(i.date),  startOfQuarter(date));
      }

      if(granularity === "month"){
        const dateToCheck = !isPreviousData ? startOfMonth(new Date(i.date)) : startOfMonth(addMonths(new Date(i.date), dateLabels.length ));
        return isEqual(
          dateToCheck,
          startOfMonth(date)
        );
      }
      const previousDateCompare = AddBasedOnPeriod[prevPeriod](startOfDay(new Date(i.date)), differenceDays);
      const startOfDate = startOfDay(date);
      return isEqual(isPreviousData ? previousDateCompare : startOfDay(new Date(i.date)), startOfDate);
    
    });
    if (foundItem) {
      isValue = true;
      return foundItem.numberOfConversation;
    }
    previousDataDates.push(null);
    return isValue ? 0 : null;
  });
  return {mappedData: convertTrailingZerosToNull(mappedData), previousDataDates};
  };
  
  function isIntervalFullMonth(interval: Date[]) {
    if(!Array.isArray(interval)) return false;
    const startOfMonthDay = startOfMonth(interval[0]);
    const endOfMonthDay = endOfMonth(interval[0]);
    return isEqual(startOfMonthDay, interval[0]) && isEqual(endOfMonthDay, interval[1]);
  }

  function convertTrailingZerosToNull(arr) {
    // Iterate through the array in reverse order
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i] !== 0) {
        break; // Stop as soon as you find a non-zero value
      }
      arr[i] = null; // Convert trailing zeros to null
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        break; // Stop as soon as you find a non-zero value
      }
      arr[i] = null; // Convert previous zeros to null
    }
    return arr;
  }

  function mergeArrays(array1, array2) {
  
    return array1.reduce((result, item, index) => {
      result.push(item, array2[index]); 
      return result;
    }, []).filter(Boolean); // Filter out any potential undefined elements
  }

  function getDaysInQuarter(date) {
    const quarterStart = startOfQuarter(date);
    const quarterEnd = endOfQuarter(date);
  
    const daysInQuarter = differenceInDays(quarterEnd, quarterStart) + 1; // Add 1 to include the end day
  
    return daysInQuarter;
  }
  function getWholeWeeksInQuarter(date) {
    const quarterStart = startOfQuarter(date);
    const quarterEnd = endOfQuarter(date);
  
    const weeksInQuarter = differenceInCalendarWeeks(quarterEnd, quarterStart);
  
    return weeksInQuarter;
  }

  function getMonthsInQuarter(date) {
    const quarterStart = startOfQuarter(date);
    const quarterEnd = endOfQuarter(date);
  
    const months = new Set(); // Use a Set to store unique months 
  
    let currentDate = quarterStart;
    while (currentDate <= quarterEnd) {
      months.add(getMonth(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
  
    return months.size;
  }
  
  function convertWeekNumberToDate(year: number, weekNumber: number, weekStartsOn = 1) {
    // WeekStartsOn:
    // * 0 - Sunday 
    // * 1 - Monday (This is often the standard)
    // * ... (Other days of the week) 
  
    const firstDayOfYear = parse(`${year}-01-01`, "yyyy-MM-dd", new Date()); 
    const firstWeekStart = startOfWeek(firstDayOfYear, { weekStartsOn }); 
  
    // Add days to get to the start of the desired week
    const targetWeekStart = addDays(firstWeekStart, (weekNumber ) * 7);
  
    return targetWeekStart;
  }
   const hashCode = (str: string) => {
    return str.split("").reduce((prevHash, currVal) =>
      (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
  };
  export {convertTrailingZerosToNull, mergeArrays, getDaysInQuarter, getWholeWeeksInQuarter, getMonthsInQuarter, isIntervalFullMonth, convertWeekNumberToDate, hashCode};