import { StorageKeys } from "@/constants/advanced-search";

export const setQueryParams = (queryParams) => {
  if (!queryParams) return;
  const search = localStorage.getItem(StorageKeys.search);
  let searchObject = {};
  if (search) {
    searchObject = JSON.parse(search);
  }
  const newSearchObject = { ...searchObject, ...queryParams };
  localStorage.setItem(StorageKeys.search, JSON.stringify(newSearchObject));
}

export const removeQueryParams = (queryParams: string[]) => {
  if (!queryParams) return;
  const search = localStorage.getItem(StorageKeys.search);
  let searchObject = {};
  if (search) {
    searchObject = JSON.parse(search);
  }
  queryParams.forEach(param => {
    delete searchObject[param];
  });
  localStorage.setItem(StorageKeys.search, JSON.stringify(searchObject));
}