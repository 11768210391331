import { supportedGranularityCustomPeriods, supportedGranularityPeriods } from "@/constants/advanced-search";
import { FilterState } from "@/types/advancedSearch";
import { 
    endOfDay, 
    endOfMonth, 
    endOfQuarter, 
    endOfWeek, 
    endOfYear, 
    format, 
    startOfDay, 
    startOfMonth, 
    startOfQuarter, 
    startOfWeek, 
    startOfYear, 
    subDays, 
    subMonths, 
    subQuarters, 
    subYears, 
    subWeeks, 
    differenceInDays 
} from "date-fns";

const getDateForInterval = (interval: string | Date[], isPrevious = false, dateFormat?: string) => {
    const today = new Date();
    let StartDate: Date;
    let EndDate: Date;
    //sort interval in case user selects a range of dates, the can be reversed
    if(interval instanceof Array) {

        interval.sort((a, b) => a.getTime() - b.getTime());
        StartDate = interval[0];
        EndDate = interval[1];
        return {
            StartDate: dateFormat ? format(startOfDay(StartDate), dateFormat) : startOfDay(StartDate),
            EndDate: dateFormat ? format(endOfDay(EndDate), dateFormat) : endOfDay(EndDate)
        };
    }
    switch(interval) {
        case "yesterday":
            StartDate = subDays(today, isPrevious ? 2 : 1);
            EndDate = subDays(today, isPrevious ? 2 : 1);
            break;
        case "sevenDays":
            StartDate = startOfWeek(subWeeks(today, 1), {weekStartsOn: 1});
            EndDate = endOfWeek(subWeeks(today, 1),{weekStartsOn: 1});
            if(isPrevious) {
                StartDate = startOfWeek(subWeeks(StartDate, 1),{weekStartsOn: 1});
                EndDate = endOfWeek(subWeeks(EndDate, 1),{weekStartsOn: 1});
            }
            break;
        case "twoWeeks":
            StartDate = startOfWeek(subWeeks(today, isPrevious ? 4 : 2), {weekStartsOn: 1});
            EndDate = endOfWeek(subWeeks(today, isPrevious ? 3 : 1), {weekStartsOn: 1});
            break;
        case "month":
            StartDate =  startOfMonth(subMonths(today, isPrevious ? 2 : 1));
            EndDate = endOfMonth(subMonths(today, isPrevious ? 2 : 1));
            break;
        case "quarter":
            StartDate = startOfQuarter(subQuarters(today,  isPrevious ? 2 : 1));
            EndDate = endOfQuarter(subQuarters(today,  isPrevious ? 2 : 1));
            break;
        case "year":
            StartDate = startOfYear(subYears(today, isPrevious ? 2 : 1));
            EndDate = endOfYear(subYears(today, isPrevious ? 2 : 1));
            break;
        default:
            throw new Error(`Invalid interval: ${interval}`);
    }
    return  !isPrevious ?{
        StartDate: dateFormat ? format(startOfDay(StartDate), dateFormat) : startOfDay(StartDate),
        EndDate: dateFormat ? format(endOfDay(EndDate), dateFormat) : endOfDay(EndDate)
    } : {
        PreviousStartDate: dateFormat ? format(StartDate, dateFormat) : StartDate,
        PreviousEndDate: dateFormat ? format(EndDate, dateFormat) : EndDate
    };
};

function adjustGranularityForPeriod(filterState: FilterState) :void {
    const {isCustomPeriod, timePeriod, dateGranularity } = filterState.intervals;
    if (!supportedGranularityPeriods[dateGranularity].includes(timePeriod) && !isCustomPeriod) {
        filterState.updateIntervals("dateGranularity", "day", false);
    }
    if(isCustomPeriod) {
      const [from, to] = timePeriod as unknown as Date[];
      if (supportedGranularityCustomPeriods[dateGranularity] > differenceInDays(to, from)) {
        filterState.updateIntervals("dateGranularity", "day", false);
      }
    }
  }

export { getDateForInterval, adjustGranularityForPeriod };